<template>
  <div class="content">
    <p class="mainTitle">影像云存储</p>
    <p class="subtitle">MEDICAL IMAGE CLOUD STORAGE</p>
    <div class="dataBackup">
      <div class="dataBackupInfo">
        <div class="dataBackupTitle">数据备份</div>
        <div class="dataBackupSubtitle">
          影像云端存储帮助医院解决生产运营系统和数据的安全。
        </div>
        <p class="dataBackupDesc">
          影像存储系统将医学影像数据从现有的医院数据中心通过网络转存至云平台，实现PACS应用备份、影像数据容灾备份等功能。满足在自然灾害、硬盘故障、黑客攻击、人为损坏等情况下，医疗机构能够持续稳定的对外提供服务。
        </p>
      </div>
      <div class="dataBackupItem">
        <div class="dataBackipItemImg">
          <img
            :src="require('@/assets/images/phone/product/xmycp_yxycc_yxygd@3x (1)_.png')"
            alt=""
          />
        </div>
        <div class="dataBackupItemInfo">
          <div class="dataBackupItemTitle">影像云归档</div>
          <p class="dataBackupItemDesc">
            用于已部署PACS的医院，医院PACS存储空间不足，不能满足医院影像数据高速增长的需求，建议本地存储高频访问的数据，云端存储历史归档数据。
          </p>
        </div>
      </div>
      <div class="dataBackupItem">
        <div class="dataBackipItemImg">
          <img
            :src="require('@/assets/images/phone/product/xmycp_yxycc_ydbf@3x (1)_.png')"
            alt=""
          />
        </div>
        <div class="dataBackupItemInfo">
          <div class="dataBackupItemTitle">影像数据异地容灾备份</div>
          <p class="dataBackupItemDesc">
            用于已部署PACS的医院，不影响现有系统，数据云端备份，本地数据损坏后按需恢复。
          </p>
        </div>
      </div>
      <div class="dataBackupItem">
        <div class="dataBackipItemImg">
          <img :src="require('@/assets/images/phone/product/xmycp_yxycc_pacs@3x.png')" alt="" />
        </div>
        <div class="dataBackupItemInfo">
          <div class="dataBackupItemTitle">PACS应用级备份</div>
          <p class="dataBackupItemDesc">
            医院有完备的信息系统，为防止业务中断，部署灾备PACS，在主用PACS系统故障后，灾备PACS系统可以应用级容灾接管，提高了医院在PACS系统出现故障后的业务持续能力。
          </p>
        </div>
      </div>
    </div>
    <div class="container deploymentSchemeContainer">
          <div class="deploymentSchemeTitle">部署方案</div>
          <p class="deploymentSchemeDesc">
            指云端数字云影像系统连接医院PACS系统，架设安全设备防火墙，通过前置机对影像数据进行采集和治理，经过无损压缩后上传至影像云平台，供医生和患者随时调阅。
          </p>
          <img
            class="deploymentSchemeImg"
            :src="require('@/assets/images/phone/product/bushufangan@3x_.png')"
            alt=""
          />

    </div>
    <div class="container partnerContainer">
      <div class="partnerTitle">合作商家</div>
      <div class="partnerSubtitle">COOPERATIVE MERCHANT</div>
      <div class="cec">
        <div class="cecImg"></div>
        <div class="cecTitle">中电数据服务有限公司</div>
        <div class="cecDesc">该企业是中央直接管理的国有重要骨干企业。国务院国资委认定的唯一一家以网络安全和信息化为核心主业的中央企业。中电数据服务有限公司（中电数据），是专注于健康医疗大数据整合、管理和应用服务的平台公司。打造数字健康智能化健康医疗服务，通过多种方式汇聚诊疗数据、行为数据和环境数据等个人相关的健康医疗大数据，构建以个人为中心的全生命周期“数字健康档案”。</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      curseId: 'partner0',
      partner: [
        {
          id: 'partner0',
          imgPath: require('@/assets/images/web/product/zhongdianshuju.png'),
          title: '中电数据项目',
          info: '中电数据项目与全国各省市共享健康医疗大数据平台，旨在云计算、大数据、物联网、移动医疗等先进信息技术与健康服务深度融合，有效解决数据共享难题，打破信息孤岛，助力医疗机构提升服务水平，支撑各省市健康医疗行业高速发展。'
        },
        {
          id: 'partner1',
          imgPath: require('@/assets/images/web/product/kaente.png'),
          title: '成都卡恩特医疗科技有限公司',
          info: '成都卡恩特医疗科技有限公司致力于智慧医疗产品研发。其核心产品指云端是基于健康医疗大数据的智慧医疗服务平台。通过共享健康医疗信息，为用户提供移动远程会诊、在线诊疗、医疗数据查阅等个性化服务。'
        },
        {
          id: 'partner2',
          imgPath: require('@/assets/images/web/product/cec.png'),
          title: '中电数据服务有限公司',
          info: '该企业是中央直接管理的国有重要骨干企业。国务院国资委认定的唯一一家以网络安全和信息化为核心主业的中央企业。中电数据服务有限公司（中电数据），是专注于健康医疗大数据整合、管理和应用服务的平台公司。打造数字健康智能化健康医疗服务，通过多种方式汇聚诊疗数据、行为数据和环境数据等个人相关的健康医疗大数据，构建以个人为中心的全生命周期“数字健康档案”。'
        }
      ]
    }
  },
  mounted: function () {
    this.setTransform()
  },
  methods: {
    partnerChange(now, last) {
      console.log(now, '当前ID')
      console.log(last, '上一个ID')
      if (now === 0) {
        this.curseId = 'partner0'
      } else if (now === 1) {
        this.curseId = 'partner1'
      } else {
        this.curseId = 'partner2'
      }
      this.setTransform()
    },
    setTransform() {
      if (this.curseId === 'partner0') {
        // 当前展示的是第0个，那么左边的轮播是第2个，右边是第1个
        console.log(this.$refs.partner0, '第0个轮播')
        console.log(this.$refs.partner1, '第1个轮播')
        console.log(this.$refs.partner2, '第2个轮播')
        // this.$refs.partner0[0].$el.style.left = '0px'
        // this.$refs.partner1[0].$el.style.left = '300px'
        // this.$refs.partner2[0].$el.style.left = '-300px'
      } else if (this.curseId === 'partner1') {
        // 当前展示的是第1个，那么左边的轮播是第0个，右边是第2个
        console.log(this.$refs.partner0, '第0个轮播')
        console.log(this.$refs.partner1, '第1个轮播')
        console.log(this.$refs.partner2, '第2个轮播')
        // this.$refs.partner0[0].$el.style.left = '-300px'
        // this.$refs.partner1[0].$el.style.left = '0px'
        // this.$refs.partner2[0].$el.style.left = '300px'
      } else {
        // 当前展示的是第2个，那么左边的轮播是第1个，右边是第0个
        console.log(this.$refs.partner0, '第0个轮播')
        console.log(this.$refs.partner1, '第1个轮播')
        console.log(this.$refs.partner2, '第2个轮播')
        // this.$refs.partner0[0].$el.style.left = '300px'
        // this.$refs.partner1[0].$el.style.left = '-300px'
        // this.$refs.partner2[0].$el.style.left = '0px'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  .container {
    width: 100%;
  }
  .mainTitle {
    text-align: center;
    margin: 0 auto;
    margin-top: 55px;
    font-size: 42px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #29262A;
  }
  .subtitle {
    text-align: center;
    margin: 0 auto;
    margin-top: 26px;
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(100, 106, 115, 0.77);
    line-height: 31px;
  }
  .dataBackup {
    overflow: hidden;
    width: 666px;
    height: fit-content;
    margin: 0 auto;
    margin-top: 50px;
    .dataBackupInfo {
      display: inline-block;
      width: 100%;
      height: 470px;
      background: url('../../../../assets/images/phone/product/xmycp_yxycc_sjbf@3x_.png') no-repeat;
      background-size: 100% 100%;
      .dataBackupTitle {
        text-align: center;
        margin-top: 46px;
        font-size: 42px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #FFFFFF;
      }
      .dataBackupSubtitle {
        text-align: center;
        margin-top: 31px;
        padding: 0 56px;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 48px;
      }
      .dataBackupDesc {
        margin: 0 auto;
        display: block;
        margin-top: 40px;
        padding: 0 33px;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #BFBEBE;
        line-height: 34px;
        letter-spacing: 1px;
      }
    }
    .dataBackupItem {
      display: inline-block;
      width: 666px;
      height: fit-content;
      margin: 0 auto;
      margin-top: 42px;
      background: #FFFFFF;
      box-shadow: 0px 7px 16px 0px rgba(12, 41, 72, 0.17);
      border-radius: 10px;
      .dataBackipItemImg {

        img{
          width: 100%;
          height: 100%;
          display: block;
        }
        width: 609px;
        height: 363px;
        margin: 0 auto;
        margin-top: 35px;
      }
      .dataBackupItemInfo {
        padding: 0 33px;

      margin-bottom: 70px;
        .dataBackupItemTitle {
          margin-top: 50px;
          font-size: 36px;
          font-family: PingFang SC;
          font-weight: 800;
          color: #111111;
        }
        .dataBackupItemDesc {
          margin-top: 32px;
          font-size: 28px;
          font-family: PingFang SC;
          color: #2D292E;
          line-height: 45px;
          letter-spacing: 1px;
        }
      }
    }
  }
  .deploymentSchemeContainer {
    background: #f9f9f9;
    height: fit-content;
    margin-top: 75px;
    padding-bottom: 69px;
    overflow: hidden;
    .deploymentSchemeTitle {
      margin-top: 73px;
      text-align: center;
      font-size: 42px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #29262A;
    }
    .deploymentSchemeDesc {
      padding: 0 50px;
      margin-top: 39px;
      font-size: 26px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #2D292E;
      line-height: 47px;
      letter-spacing: 1px;
    }
    .deploymentSchemeImg {
        display: block;
        width: 628px;
        height: 542px;
        margin: 0 auto;
        margin-top: 62px;
    }
  }
  .partnerContainer {
    height: fit-content;
    padding-bottom: 77px;
    background: white;
    display: block;
    .partnerTitle {
      margin-top: 47px;
      text-align: center;
      font-size: 42px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #29262A;
    }
    .partnerSubtitle {
      text-align: center;
      color: rgba(0,0,0, 0.755);
      margin-top: 27px;
      font-size: 28px;
      font-family: AngsanaUPC;
      font-weight: 400;
    }
    .cec{
      width: 672px;
      height: fit-content;
      margin: 0 auto;
      margin-top: 51px;
      .cecImg{
        width: 672px;
        height: 357px;
        background: url('../../../../assets/images/phone/product/zhongdiangongsi@3x.png') no-repeat;
        background-size: 100% 100%;
      }

        .cecTitle{
          margin-top: 61px;
          font-size: 34px;
          font-family: PingFang SC;
          font-weight: 800;
          color: #29262A;
          line-height: 28px;
        }
          .cecDesc{
            margin-top: 33px;
            font-size: 28px;
            font-family: PingFang SC;
            color: #2D292E;
            line-height: 45px;
            letter-spacing: 3px;
          }
        
    }
  }
}
</style>
